import { DocumentType } from '@/core/enums/documentType';
import type { RequiredDocument } from '@/types/RequiredDocument';
import { CLIENT, WORKER } from '@/utils/enums/UserTypes';
import { find, set } from 'lodash';
import { defineStore } from 'pinia';
import type { User } from '../types';

export const useAuthStore = defineStore('auth', () => {
  const defaultValues = {
    skipOnboarding: false,
    skipSubscription: false,
  };
  const options = ref(defaultValues);

  const setOptions = (params: Record<string, any>) => {
    Object.keys(params).forEach((key) => {
      set(options.value, key, params[key]);
    });
  };

  const resetOptions = () => {
    options.value = defaultValues;
  };

  const hasUpdatedProfile = ref(false);
  const { data } = useAuth();
  const user = ref<User>(data.value?.data || null);

  const getUser = computed(() => user.value || ({} as User));
  const setUser = (newUser: User) => (user.value = newUser);

  // Working with Children title
  const WWCTitle = computed(() => {
    if (user.value?.place_state_short_name === 'QLD') {
      return 'Blue Card';
    } else if (user.value?.place_state_short_name === 'TAS' || user.value?.place_state_short_name === 'ACT') {
      return 'Working with Vulnerable Persons';
    }

    return 'Working With Children';
  });

  const onboardingCompleted = computed(() => Boolean(user.value && user.value?.onboarded_at));
  const hasLoggedInUser = computed(() => Boolean(user.value));
  const hasCompletedOnboarding = computed(() => user.value && user.value?.profile_completed === true);

  const fullAddress = computed((): string => {
    return hasLoggedInUser.value && (user.value.address || user.value.address_2)
      ? `${user.value.address ? user.value.address : ''} ${user.value.address_2 ? user.value.address_2 : ''}`.trim()
      : '';
  });
  const stateAndSuburb = computed((): string => {
    return hasLoggedInUser.value && (user.value?.place_city || user.value?.place_state_short_name)
      ? `${user.value.place_city ? `${user.value.place_city},` : ''} ${user.value.place_state_short_name}`.trim()
      : '';
  });

  const isVerified = computed(() => user.value?.email_verified);

  const requireSupportSchedule = computed(() => {
    // @NOTE: When the user has `not sure yet` value, schedule array gets populated automatically
    return hasLoggedInUser.value
      ? (user.value.require_support_schedule || []).filter((a) => a.start !== '00:00:00' && a.end !== '23:59:00')
      : [];
  });

  const isClient = computed(() => user.value?.user_type === CLIENT);
  const isWorkHoursValid = computed((): boolean => {
    if (!hasLoggedInUser.value) return false;

    return !!requireSupportSchedule.value.length;
  });
  const isSupportPreferenceValid = computed((): boolean => {
    return hasLoggedInUser.value
      ? !!user.value.support_preference?.profile_visibility &&
          !!user.value.support_preference?.genders.length &&
          !!user.value.support_preference?.car_ownership
      : false;
  });
  const isSupportRequirementsComplete = computed((): boolean => {
    if (isClient.value) {
      return isWorkHoursValid.value && isSupportPreferenceValid.value;
    }

    return false;
  });

  const isWorker = computed(() => user.value?.user_type === WORKER);
  const isServicesOfferedValid = computed((): boolean => {
    return hasLoggedInUser.value ? !!user.value.services_offered?.length : false;
  });
  const isRatesValid = computed((): boolean => {
    return hasLoggedInUser.value
      ? !!user.value.indicative_rates?.travel_per_km_rate &&
          !!user.value.indicative_rates?.weekday_hourly_rate &&
          !!user.value.indicative_rates?.weekday_night_hourly_rate &&
          !!user.value.indicative_rates?.weekend_hourly_rate &&
          !!user.value.indicative_rates?.weekend_night_hourly_rate
      : false;
  });
  const isAvailabilityValid = computed((): boolean => {
    return hasLoggedInUser.value ? !!user.value.availability_schedule?.length : false;
  });
  const isClientPreferenceValid = computed((): boolean => {
    return hasLoggedInUser.value
      ? !!user.value.client_preference?.genders?.length &&
          !!user.value.client_preference?.car_ownership &&
          !!user.value.client_preference?.range_of_work_in_km
      : false;
  });
  const isServicesOfferedComplete = computed((): boolean => {
    if (isWorker.value) {
      return (
        isServicesOfferedValid.value && isRatesValid.value && isAvailabilityValid.value && isClientPreferenceValid.value
      );
    }

    return false;
  });

  const getDocument = (documentType: string): RequiredDocument | null => {
    return hasLoggedInUser.value ? find(user.value.documents, { document_type: documentType }) : null;
  };

  const isDocumentValid = (documentType: string): boolean | null => {
    if (!hasLoggedInUser.value) return false;

    const exist = getDocument(documentType);
    if (exist) {
      return !!exist.approved_at;
    }

    return null;
  };

  const isIdDocumentValid = computed((): boolean => {
    return isDocumentValid(isClient.value ? DocumentType.ID_VERIFICATION : DocumentType.ID_DOCUMENTS);
  });
  const isPoliceCheckValid = computed((): boolean => {
    return isDocumentValid(DocumentType.POLICE_CHECKS);
  });
  const isAbnValid = computed((): boolean => {
    return isDocumentValid(DocumentType.ABN);
  });
  const isWwcValid = computed((): boolean => {
    return isDocumentValid(DocumentType.WWC);
  });
  const isFirstAidValid = computed((): boolean => {
    return isDocumentValid(DocumentType.FIRST_AID);
  });
  const isPublicLiabilityValid = computed((): boolean => {
    return isDocumentValid(DocumentType.PUBLIC_LIABILITY);
  });
  const isNdisValid = computed((): boolean => {
    return isDocumentValid(DocumentType.NDIS_ORIENTATION_MODULE);
  });
  const isCovidVaccinationValid = computed((): boolean => {
    return isDocumentValid(DocumentType.COVID_VACCINATION);
  });
  const isRequiredDocumentsValid = computed((): boolean => {
    if (isClient.value) {
      return isIdDocumentValid.value;
    } else if (isWorker.value) {
      return (
        isIdDocumentValid.value &&
        isPoliceCheckValid.value &&
        isAbnValid.value &&
        isWwcValid.value &&
        isFirstAidValid.value &&
        isPublicLiabilityValid.value
      );
    }

    return false;
  });
  const isProfileComplete = computed((): boolean => {
    return isRequiredDocumentsValid.value;
  });

  watch(
    () => data.value,
    () => {
      if (data.value?.data) {
        user.value = data.value?.data;
      }
    },
    { immediate: true }
  );

  return {
    options,
    setOptions,
    resetOptions,
    hasUpdatedProfile,
    user,
    getUser,
    setUser,
    onboardingCompleted,
    hasLoggedInUser,
    hasCompletedOnboarding,
    fullAddress,
    stateAndSuburb,
    WWCTitle,
    isVerified,
    requireSupportSchedule,
    isClient,
    isWorkHoursValid,
    isSupportPreferenceValid,
    isSupportRequirementsComplete,
    isWorker,
    isServicesOfferedValid,
    isRatesValid,
    isServicesOfferedComplete,
    getDocument,
    isIdDocumentValid,
    isPoliceCheckValid,
    isAbnValid,
    isWwcValid,
    isFirstAidValid,
    isPublicLiabilityValid,
    isNdisValid,
    isCovidVaccinationValid,
    isRequiredDocumentsValid,
    isProfileComplete,
    isAvailabilityValid,
    isClientPreferenceValid,
  };
});
