export enum DocumentType {
  ID_VERIFICATION = 'id_verification',
  ID_DOCUMENTS = 'id_documents',
  POLICE_CHECKS = 'police_checks',
  ABN = 'abn',
  WWC = 'wwwc',
  FIRST_AID = 'first_aid',
  CPR_CERTIFICATE = 'cpr_certificate',
  PUBLIC_LIABILITY = 'public_liability',
  NDIS_ORIENTATION_MODULE = 'ndis_orientation_module',
  COVID_VACCINATION = 'covid_vaccination',
}
